import React, { Component } from "react";
import { pages } from "../pages.js";

import { db } from "../firebase";
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  where,
  increment,
} from "firebase/firestore";

class Game extends React.Component {
  constructor(props) {
    super(props);
    this.observer = this.props.observer;
    this.state = {
      bothPlayersHere: false,
      leftScore: 0,
      rightScore: 0,
      player1: {
        id: 0,
        score: 0,
        hand: 0,
        username: "",
      },
      player1Stats: 0,
      player1Username: "",
      player2: {
        id: 0,
        score: 0,
        hand: 0,
        username: "",
      },
      player2Stats: 0,
      player2Username: "",
      subtitle: "Best of 5 ",
    };
    this.serverCollectionRef = null;
    this.currentUserAsPlayer = null;

    this.referenceToCurrentUserInServer = null;
    //this.referenceToCurrentUserInUsers = null;
  }

  getUserById = async (id) => {
    const docRef = doc(db, "users", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  };

  // getNumUsers = async (ref) => {
  //   const data = await getDocs(ref);
  //   if (data.empty) return 0;

  //   let retVal = 0;
  //   let test = data.docs.map((doc) => {
  //     retVal++;
  //   });
  //   return retVal;
  // };

  getReferencesForThePlayer = () => {
    this.currentUserAsPlayer =
      this.props.currentUserId == this.state.player1.id
        ? "Player 1"
        : "Player 2";
    console.log("current User as Player");
    console.log(this.currentUserAsPlayer);
    this.referenceToCurrentUserInServer = doc(
      db,
      "server " + this.props.currentServerNumber,
      this.currentUserAsPlayer
    );
  };

  componentDidMount() {
    //console.log(this.props.currentServerNumber);
    this.serverCollectionRef = collection(
      db,
      "server " + this.props.currentServerNumber
    );

    this.observer.subscribe("responseAccountInfo", (accountInfo) => {
      // console.log("accountInfo");
      // console.log(accountInfo);
      if (this.state.player1.id == accountInfo.id) {
        this.setState({
          player1Username: accountInfo.accountInfo.username,
          player1Stats: {
            rock: accountInfo.accountInfo.rock,
            paper: accountInfo.accountInfo.paper,
            scissors: accountInfo.accountInfo.scissors,
          },
          //player1: { username: accountInfo.accountInfo.username },
        });
      } else if (this.state.player2.id == accountInfo.id) {
        this.setState({
          player2Username: accountInfo.accountInfo.username,
          player2Stats: {
            rock: accountInfo.accountInfo.rock,
            paper: accountInfo.accountInfo.paper,
            scissors: accountInfo.accountInfo.scissors,
          },
          //player2: { username: accountInfo.accountInfo.username },
        });
      }
    });

    let player1Username = "";
    let player2Username = "";

    const player1Listener = onSnapshot(
      doc(db, "server " + this.props.currentServerNumber, "Player 1"),
      (doc) => {
        console.log(this.state.player1Username + " : ", doc.data());
        if (doc.data() !== undefined) {
          this.setState({
            player1: {
              id: doc.data().id,
              score: doc.data().score,
              hand: doc.data().hand,
            },
          });

          //player1Username = doc.data().username;

          this.getReferencesForThePlayer();
          this.observer.publish("requestAccountInfo", doc.data().id);
        }
      }
    );

    const player2Listener = onSnapshot(
      doc(db, "server " + this.props.currentServerNumber, "Player 2"),
      (doc) => {
        console.log(this.state.player2Username + " : ", doc.data());
        if (doc.data() !== undefined) {
          this.setState({
            player2: {
              id: doc.data().id,
              score: doc.data().score,
              hand: doc.data().hand,
            },
          });

          // player2Username = doc.data().username;

          this.observer.publish("requestAccountInfo", doc.data().id);
          this.setState({ bothPlayersHere: true });
        }
      }
    );

    // this.setState({ player1Username: player1Username });
    // this.setState({ player2Username: player2Username });

    //console.log("both Players here");
    //console.log(this.state.bothPlayersHere);
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
    this.observer.unsubscribe("responseAccountInfo");
  }

  getTitle = () => {
    if (this.state.bothPlayersHere == false) return "Wait";
    else return "RPS";
  };

  // setPlayerUsernames = async () => {
  //   const data = await getDocs(this.serverCollectionRef);

  //   let players = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  //   players.map((player) => {
  //     if (this.state.player1.id == player.id) {
  //       console.log("setting player 1 username to: " + player.username);
  //       this.setState({ player1Username: player.username });
  //     } else if (this.state.player2.id == player.id) {
  //       console.log("setting player 2 username to: " + player.username);
  //       this.setState({ player2Username: player.username });
  //     }
  //     console.log("no user with matching id");
  //   });
  // };

  getUsers = async () => {
    const data = await getDocs(this.serverCollectionRef);

    let players = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    console.log(players);
    players.map((player) => {
      console.log("ID " + player.id);
      console.log("score: " + player.score);
    });
  };

  endGame = () => {
    if (this.state.player1.score >= 3 || this.state.player2.score >= 3) {
      return " notInteractableColor";
    } else return " defaultColor";
  };

  referenceToCurrentUserInUsers = doc(db, "users", this.props.currentUserId);

  getPlayerState() {
    if (this.currentUserAsPlayer == "Player 1") {
    } else {
    }
  }

  displayPlayer1() {
    if (this.state.player1Username == "") {
      return "?";
    }
    if (this.state.player1.hand == 0) return "🤔";
    else if (this.state.player2.hand == 0) {
      return "🤞";
    } else {
      if (this.state.player1.hand == "rock") return "🗿";
      if (this.state.player1.hand == "paper") return "📰";
      if (this.state.player1.hand == "scissors") return "✂️";
    }
  }

  displayPlayer2() {
    if (this.state.player2Username == "") {
      return "?";
    }
    if (this.state.player2.hand == 0) return "🤔";
    else if (this.state.player1.hand == 0) {
      return "🤞";
    } else {
      if (this.state.player2.hand == "rock") return "🗿";
      if (this.state.player2.hand == "paper") return "📰";
      if (this.state.player2.hand == "scissors") return "✂️";
    }
  }

  handleRPSForCurrentUser = async () => {
    let p1Hand = this.state.player1.hand;
    let p2Hand = this.state.player2.hand;

    //this.setState({ player1: { hand: 0 }, player2: { hand: 0 } });
    console.log(
      "after resetting to make sure other properties are still there"
    );
    console.log("p1: " + this.state.player1);
    console.log(this.state.player1);
    console.log("p1: " + this.state.player2);
    console.log(this.state.player2);
    //this runs on both sides so only worry about updating the current user
    if (this.currentUserAsPlayer == "Player 1") {
      if (
        (this.state.player1.hand == "rock" &&
          this.state.player2.hand == "scissors") ||
        (this.state.player1.hand == "paper" &&
          this.state.player2.hand == "rock") ||
        (this.state.player1.hand == "scissors" &&
          this.state.player2.hand == "paper")
      ) {
        console.log(this.state.player1Username + " scored");
        await updateDoc(this.referenceToCurrentUserInServer, {
          score: increment(1),
          hand: 0,
        });
        //this.setState({ player1: { hand: 0 }, player2: { hand: 0 } });
      }
    }
    if (this.currentUserAsPlayer == "Player 2") {
      if (
        (this.state.player2.hand == "rock" &&
          this.state.player1.hand == "scissors") ||
        (this.state.player2.hand == "paper" &&
          this.state.player1.hand == "rock") ||
        (this.state.player2.hand == "scissors" &&
          this.state.player1.hand == "paper")
      ) {
        console.log(this.state.player2Username + " scored");
        await updateDoc(this.referenceToCurrentUserInServer, {
          score: increment(1),
          hand: 0,
        });
        //this.setState({ player1: { hand: 0 }, player2: { hand: 0 } });
      }
    }

    if (this.isWinner()) {
      if (this.state.player1.score >= 3) {
        let p1Name = this.state.player1Username + " wins!";
        let p1temp = { subtitle: p1Name };
        this.setState(p1temp);
        if (this.state.currentUserAsPlayer == "Player 1") {
          await updateDoc(this.referenceToCurrentUserInUsers, {
            wins: increment(1),
          });
        } else {
          await updateDoc(this.referenceToCurrentUserInUsers, {
            losses: increment(1),
          });
        }
      }
      if (this.state.player2.score >= 3) {
        let p2Name = this.state.player2Username + " wins!";
        let p2temp = { subtitle: p2Name };
        this.setState(p2temp);
        if (this.state.currentUserAsPlayer == "Player 2") {
          await updateDoc(this.referenceToCurrentUserInUsers, {
            wins: increment(1),
          });
        } else {
          await updateDoc(this.referenceToCurrentUserInUsers, {
            losses: increment(1),
          });
        }
      }
    } else {
      setTimeout(async () => {
        await updateDoc(this.referenceToCurrentUserInServer, {
          hand: 0,
        });
      }, 3000);
    }
  };

  isWinner() {
    if (this.state.player1.score >= 3 || this.state.player2.score >= 3)
      return true;
    else return false;
  }

  getPercentage(player, hand) {
    //console.log("player");
    //console.log(player);

    if (player == this.state.player1 && this.state.player1.id == 0) return "?";
    if (player == this.state.player2 && this.state.player2.id == 0) return "?";

    if (
      player == this.state.player1 &&
      this.state.player1Stats.rock == 0 &&
      this.state.player1Stats.paper == 0 &&
      this.state.player1Stats.scissors == 0
    ) {
      return 0;
    }
    if (
      player == this.state.player2 &&
      this.state.player2Stats.rock == 0 &&
      this.state.player2Stats.paper == 0 &&
      this.state.player2Stats.scissors == 0
    ) {
      return 0;
    }

    if (player == this.state.player1 && hand == "rock") {
      return (
        (this.state.player1Stats.rock /
          (this.state.player1Stats.rock +
            this.state.player1Stats.paper +
            this.state.player1Stats.scissors)) *
        100
      ).toFixed(0);
    }
    if (player == this.state.player1 && hand == "paper") {
      return (
        (this.state.player1Stats.paper /
          (this.state.player1Stats.rock +
            this.state.player1Stats.paper +
            this.state.player1Stats.scissors)) *
        100
      ).toFixed(0);
    }
    if (player == this.state.player1 && hand == "scissors") {
      return (
        (this.state.player1Stats.scissors /
          (this.state.player1Stats.rock +
            this.state.player1Stats.paper +
            this.state.player1Stats.scissors)) *
        100
      ).toFixed(0);
    }

    if (player == this.state.player2 && hand == "rock") {
      return (
        (this.state.player2Stats.rock /
          (this.state.player2Stats.rock +
            this.state.player2Stats.paper +
            this.state.player2Stats.scissors)) *
        100
      ).toFixed(0);
    }
    if (player == this.state.player2 && hand == "paper") {
      return (
        (this.state.player2Stats.paper /
          (this.state.player2Stats.rock +
            this.state.player2Stats.paper +
            this.state.player2Stats.scissors)) *
        100
      ).toFixed(0);
    }
    if (player == this.state.player2 && hand == "scissors") {
      return (
        (this.state.player2Stats.scissors /
          (this.state.player2Stats.rock +
            this.state.player2Stats.paper +
            this.state.player2Stats.scissors)) *
        100
      ).toFixed(0);
    }
  }

  render() {
    if (this.state.player1.hand != 0 && this.state.player2.hand != 0) {
      this.handleRPSForCurrentUser();
    }

    return (
      <div className="fullScreen">
        <div className="grid-container fillParent">
          <div className={"statsBar1" + this.endGame()}>
            🗿:{this.getPercentage(this.state.player1, "rock")}% 📰:
            {this.getPercentage(this.state.player1, "paper")}% ✂️:
            {this.getPercentage(this.state.player1, "scissors")}%
          </div>
          <div className={"statsBar2" + this.endGame()}>
            🗿:{this.getPercentage(this.state.player2, "rock")}% 📰:
            {this.getPercentage(this.state.player2, "paper")}% ✂️:
            {this.getPercentage(this.state.player2, "scissors")}%
          </div>
          <div className={"playerSide" + this.endGame()}>
            <div>{this.state.player1Username}</div>
            <div className="bigEmoji">{this.displayPlayer1()}</div>
          </div>
          <div className={"opponentSide" + this.endGame()}>
            <div>{this.state.player2Username}</div>
            <div className="bigEmoji">{this.displayPlayer2()}</div>
          </div>
          <div className={"whatWillYouDo" + this.endGame()}>
            What will you do?
          </div>
          <button
            className={"rock" + this.endGame()}
            onClick={async () => {
              console.log("click rock");
              if (
                this.state.player1.hand == 0 ||
                this.state.player2.hand == 0
              ) {
                await updateDoc(this.referenceToCurrentUserInUsers, {
                  rock: increment(1),
                });
                await updateDoc(this.referenceToCurrentUserInServer, {
                  hand: "rock",
                });
              }
            }}
          >
            rock
          </button>
          <button
            className={"paper" + this.endGame()}
            onClick={async () => {
              console.log("click paper");
              if (
                this.state.player1.hand == 0 ||
                this.state.player2.hand == 0
              ) {
                await updateDoc(this.referenceToCurrentUserInUsers, {
                  rock: increment(1),
                });
                await updateDoc(this.referenceToCurrentUserInServer, {
                  hand: "paper",
                });
              }
            }}
          >
            paper
          </button>
          <button
            className={"scissors" + this.endGame()}
            onClick={async () => {
              console.log("click scissors");
              if (
                this.state.player1.hand == 0 ||
                this.state.player2.hand == 0
              ) {
                await updateDoc(this.referenceToCurrentUserInUsers, {
                  rock: increment(1),
                });
                await updateDoc(this.referenceToCurrentUserInServer, {
                  hand: "scissors",
                });
              }
            }}
          >
            scissors
          </button>
          <button
            onClick={async () => {
              console.log("Gameside Back Button Pressed");
              await deleteDoc(this.referenceToCurrentUserInServer);
              this.observer.publish("changePage", pages.ONLINE);
            }}
            className="defaultColor run"
          >
            run
          </button>
        </div>
        <div className="title">{this.getTitle()}</div>
        <div className="subtitle">Server {this.props.currentServerNumber}</div>
        <div className="subsubtitle">{this.state.subtitle}</div>
        <div className="leftScore">{this.state.player1.score}</div>
        <div className="rightScore">{this.state.player2.score}</div>
      </div>
    );
  }
}

export default Game;

{
  /* <div className="hand">🗿</div>
<div className="hand">📰</div>
<div className="hand">✂️</div> */
}
