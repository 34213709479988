import React, { Component } from "react";
import { pages } from "../pages.js";

import { db } from "../firebase";
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  where,
} from "firebase/firestore";

class ServerList extends React.Component {
  constructor(props) {
    super(props);
    this.observer = props.observer;
    this.state = {
      server1: false,
      server2: false,
      server3: false,
      server4: false,
      server5: false,
      server6: false,
      server7: false,
    };
    this.numServers = [1, 2, 3, 4, 5, 6, 7];
    this.temp = [];
  }

  componentDidMount() {
    this.numServers.map((num) => {
      this.setAvailableForJoining(num);
    });
  }

  setAvailableForJoining = async (serverNumber) => {
    this.serverCollectionRef = collection(db, "server " + serverNumber);

    let numUsers = await this.getNumUsers(this.serverCollectionRef);
    //console.log("server: " + serverNumber);
    //console.log(numUsers);
    let serverName = "server" + serverNumber;
    let obj = {};

    if (numUsers >= 2) {
      obj[serverName] = false;
    } else {
      obj[serverName] = true;
    }
    //console.log("setting State to ");
    //console.log(obj);
    this.setState(obj);
  };

  getNumUsers = async (ref) => {
    const data = await getDocs(ref);
    if (data.empty) return 0;

    let retVal = 0;
    data.docs.map((doc) => {
      retVal++;
    });
    return retVal;
  };

  render() {
    let isBottomSideDashedBorder = true;
    let getBorder = () => {
      return isBottomSideDashedBorder ? "bottomSideDashedBorder" : "";
    };
    let getColor = (serverNumber) => {
      let serverName = "server" + serverNumber;
      if (this.state[serverName]) return "defaultColor ";
      else return "notInteractableColor ";
    };
    return (
      <>
        <div className="defaultColor  leftSideDashedBorder fillParent flex-container hideOverflow">
          {this.numServers.map((serverNumber) => {
            if (serverNumber == 7) {
              isBottomSideDashedBorder = false;
            }
            //console.log("serverAvailability is ");
            //console.log(this.state);
            return (
              <button
                key={serverNumber}
                className={getColor(serverNumber) + " " + getBorder()}
                onClick={() => {
                  this.observer.publish("changeServer", serverNumber);
                  this.observer.publish("changePage", pages.ARENA);
                }}
              >
                Server {serverNumber}
              </button>
            );
          })}
        </div>
      </>
    );
  }
}

export default ServerList;
