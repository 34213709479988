import React, { Component } from "react";
import { pages } from "../pages.js";
import InputField from "./inputField.jsx";
import Login from "./login.jsx";
import Back from "./back.jsx";

import ServerList from "./serverList.jsx";

class RightSide extends React.Component {
  constructor(props) {
    super(props);
    this.observer = props.observer;
  }

  pageFinder() {
    switch (this.props.page) {
      case pages.TITLE_PAGE:
        return (
          <button
            onClick={() => {
              console.log("rightSide TITLE_PAGE call made");
              this.observer.publish("changePage", pages.CREATE_ACCOUNT);
            }}
            className="fillParent leftSideDashedBorder defaultColor homePage"
          >
            Create Account
          </button>
        );

      case pages.LOGIN: //TODO: add autofocus attribute to a sides input field when a sides button is clicked
        return (
          <div className="fillParent">
            <Login type="Login" observer={this.observer} />

            <button
              style={{ position: "absolute", display: "block", zIndex: 0 }}
              className="cursorText fillWithOption defaultColor leftSideDashedBorder"
              onClick={() => {
                document.getElementById("LoginPassword").focus();
              }}
            >
              <p className="login">Password</p>
              <InputField
                title="Must be at least 6 characters"
                side="right"
                observer={this.observer}
                onChange={this.props.onLoginInputChange}
                type="password"
                minLength="6"
                action=""
                name="LoginPassword"
                id="LoginPassword"
              />
            </button>
          </div>
        ); //TODO ACTION

      case pages.HOMEPAGE:
        return (
          <div className="fillParent leftSideDashedBorder ">
            <button
              className="defaultColor topOption bottomSideDashedBorder "
              onClick={() => {
                this.observer.publish("logout");
                this.observer.publish("changePage", pages.TITLE_PAGE);
              }}
            >
              Logout
            </button>
            <button
              onClick={() => {
                this.observer.publish("changePage", pages.ONLINE);
              }}
              className="fillWithOption defaultColor"
            >
              Online
            </button>
          </div>
        );

      case pages.ACCOUNT:
        return (
          <button
            onClick={() => {
              console.log("Accountside Back Button Pressed");
              this.observer.publish("changePage", pages.HOMEPAGE);
            }}
            className="fillParent leftSideDashedBorder defaultColor homePage"
          >
            Back
          </button>
        );

      case pages.ONLINE:
        return <ServerList observer={this.observer} />;

      default:
        return <h1>Fail</h1>;
    }
  }

  render() {
    return <div className="rightHalf hideOverflow">{this.pageFinder()}</div>;
  }
}

export default RightSide;
