export const pages = {
  TITLE_PAGE: "Title Page",
  LOGIN: "Login Page",
  CREATE_ACCOUNT: "Create Account",
  HOMEPAGE: "Home Page",
  ACCOUNT: "Account Page",
  LOCAL: "Local Play",
  ONLINE: "Online Play",
  ARENA: "Arena",
};
