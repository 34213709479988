import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDcOQQy6LoYosuG9345EbhTxVhICHIXNm8",
  authDomain: "rps1-6d6f3.firebaseapp.com",
  projectId: "rps1-6d6f3",
  storageBucket: "rps1-6d6f3.appspot.com",
  messagingSenderId: "746702569335",
  appId: "1:746702569335:web:10effb42309cae79f8eb72",
  measurementId: "G-T77S92VPCC",
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
