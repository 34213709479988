import React, { Component } from "react";
import RightSide from "./rightSide";
import LeftSide from "./leftSide";
import { pages } from "../pages.js";
import ReactObserver from "react-event-observer";
import CreateAccount from "./createAccount";
import Game from "./game";

import { db } from "../firebase";
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  where,
} from "firebase/firestore";
import { async } from "@firebase/util";

// let serverUrl = "13.58.152.185"
// serverUrl = "localhost"

class FullPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoginSubmittableLeft: false,
      isLoginSubmittableRight: false,
      isCreateAccountSubmittableLeft: false,
      isCreateAccountSubmittableMiddle: false,
      isCreateAccountSubmittableRight: false,

      username: null,
      password: null,
      createUsername: null,
      createPassword: null,
      confirmPassword: null,

      currentUserId: null,

      currentServerNumber: null,

      preventPageChange: false,
      page: pages.TITLE_PAGE,
    };
    this.observer = ReactObserver();
  }

  usersCollectionRef = collection(db, "users");

  getNumUsers = async (ref) => {
    const data = await getDocs(ref);
    if (data.empty) return 0;

    let retVal = 0;
    let test = data.docs.map((doc) => {
      retVal++;
    });
    return retVal;
  };

  getUser = async (username, password) => {
    //console.log(
    //  "getUser called with username, password" + username + ", " + password
    //);
    let returnValue;
    const q = query(
      this.usersCollectionRef,
      where("username", "==", username),
      where("password", "==", password)
    );

    const querySnapshot = await getDocs(q);
    //console.log("querySnapshot");
    //console.log(querySnapshot);
    querySnapshot.forEach((doc) => {
      //console.log("user found, returning");
      //console.log(doc.id, " => ", doc.data());
      returnValue = doc;
    });
    return returnValue;
  };

  getUserById = async (id) => {
    const docRef = doc(db, "users", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  };

  createUser = async (username, password) => {
    const docId = await addDoc(this.usersCollectionRef, {
      username: username,
      password: password,
      rock: 0.0,
      paper: 0.0,
      scissors: 0.0,
      wins: 0.0,
      losses: 0.0,
    });
    return docId;
  };

  updateUser = async (id, newPassword) => {
    let userDoc = doc(db, "users", id);
    const newFields = { password: newPassword };
    await updateDoc(userDoc, newFields);
  };

  deleteUser = async (id) => {
    let userDoc = doc(db, "users", id);
    await deleteDoc(userDoc);
  };

  componentDidMount() {
    this.observer.subscribe("changeServer", (serverNum) => {
      this.setState({ currentServerNumber: serverNum });
    });

    this.observer.subscribe("requestAccountInfo", async (id) => {
      //console.log("request account info published too with id");
      //console.log(id);
      let accountInfo = {
        wins: 0,
        losses: 0,
        rock: 0,
        paper: 0,
        scissors: 0,
        username: 0,
      };

      let account = await this.getUserById(id);
      //console.log(account);
      accountInfo.wins = account.wins;
      accountInfo.losses = account.losses;
      accountInfo.rock = account.rock;
      accountInfo.paper = account.paper;
      accountInfo.scissors = account.scissors;
      accountInfo.username = account.username;

      this.observer.publish("responseAccountInfo", {
        id: id,
        accountInfo: accountInfo,
      });
    });

    this.observer.subscribe("changePage", (data) => {
      let cond1 = !this.state.preventPageChange;
      let cond2 =
        data == pages.TITLE_PAGE ||
        data == pages.CREATE_ACCOUNT ||
        data == pages.LOGIN;
      let condition = cond1 || cond2;
      //console.log(data);
      //console.log(cond1);
      //console.log(cond2);
      //console.log(condition);
      if (condition) this.setState({ page: data });
      if (data == pages.ARENA) {
        this.serverCollectionRef = collection(
          db,
          "server " + this.state.currentServerNumber
        );
        console.log("adding to server");
        this.addToServer(this.state.currentUserId);
      }
    });

    this.observer.subscribe("setLoginSubmittableFalse", () => {
      this.setState({
        isLoginSubmittableLeft: false,
        isLoginSubmittableRight: false,
      });
    });

    this.observer.subscribe("logout", () => {
      this.observer.publish("setLoginSubmittableFalse");
      this.observer.publish("setCreateAccountSubmittableFalse");
      this.setState({
        username: null,
        password: null,
        createPassword: null,
        confirmPassword: null,
        currentUserId: null,
      });
    });

    this.observer.subscribe("login", async (type) => {
      this.setState({ preventPageChange: true });

      let tempUser;
      let tempPass;

      if (type === "Create Account") {
        tempUser = this.state.createUsername;
        tempPass = this.state.createPassword;
      } else if (type === "Login") {
        tempUser = this.state.username;
        tempPass = this.state.password;
      }

      let user = await this.getUser(tempUser, tempPass);
      //console.log("initial user search returned");
      //console.log(user);
      if (user !== undefined) {
        //user does exist
        //console.log("user exists");
        //console.log(user.data());
        if (type === "Create Account") {
          alert("User already exists");
        } else if (type === "Login") {
          //console.log("Login Successful");
          this.setState({
            preventPageChange: false,
            currentUserId: user.id,
          });
          //console.log("currentUserId");
          //console.log(this.state.currentUserId);

          //get where youre going
          this.observer.publish("changePage", pages.HOMEPAGE);
        }
      } else {
        //user doesnt exist
        //console.log("user doesnt exists");
        if (type === "Create Account") {
          let tempId = await this.createUser(
            this.state.createUsername,
            this.state.createPassword
          );
          let user = await this.getUser(
            this.state.createUsername,
            this.state.createPassword
          );
          if (user !== undefined) {
            //console.log("User successfully created");
            this.setState({
              preventPageChange: false,
              currentUserId: user.id,
              username: user.data().username,
            });
            //console.log("currentUserId");
            //console.log(this.state.currentUserId);
          } else {
            //console.log("User creation unsuccessful");
            alert("User creation unsuccessful");
          }
          this.observer.publish("changePage", pages.HOMEPAGE);
        } else if (type === "Login") {
          alert("User doesn't exist");
        }
      }
    });

    this.observer.subscribe("setCreateAccountSubmittableFalse", () => {
      this.setState({
        isCreateAccountSubmittableLeft: false,
        isCreateAccountSubmittableMiddle: false,
        isCreateAccountSubmittableRight: false,
      });
    });

    this.observer.subscribe("inputFieldChanged", (data) => {
      // console.log("inputFieldChanged published to with data: " + data.name);
      // console.log("inputFieldChanged published to with other data: " + data.value );
      switch (data.name) {
        case "LoginUserName":
          if (data.value.length >= 3) {
            this.setState({
              isLoginSubmittableLeft: true,
              username: data.value,
            });
            break;
          } else {
            //console.log("username is too small");
            this.setState({
              isLoginSubmittableLeft: false,
              username: data.value,
            });
          }
          break;
        case "LoginPassword":
          //console.log("LoginPassword has run its switch statement");
          if (data.value.length >= 6) {
            this.setState({
              isLoginSubmittableRight: true,
              password: data.value,
            });
            break;
          } else
            this.setState({
              isLoginSubmittableRight: false,
              password: data.value,
            });
          break;
        case "CreateUserName":
          if (data.value.length >= 3) {
            this.setState({
              isCreateAccountSubmittableLeft: true,
              createUsername: data.value,
            });
            break;
          } else
            this.setState({
              isCreateAccountSubmittableLeft: false,
              createUsername: data.value,
            });
          break;
        case "CreatePassword":
          if (data.value.length >= 6) {
            this.setState({
              isCreateAccountSubmittableMiddle: true,
              createPassword: data.value,
            });
            break;
          } else
            this.setState({
              isCreateAccountSubmittableMiddle: false,
              createPassword: data.value,
            });
          break;
        case "ConfirmPassword":
          if (data.value.length >= 6) {
            this.setState({
              isCreateAccountSubmittableRight: true,
              confirmPassword: data.value,
            });
            break;
          } else
            this.setState({
              isCreateAccountSubmittableRight: false,
              confirmPassword: data.value,
            });
          break;
        default:
          console.log("inputFieldChanged Failed");
      }
      //console.log(this.state);
      let isLoginSubmittable =
        (this.state.isLoginSubmittableLeft == true &&
          this.state.isLoginSubmittableRight == true) ||
        (this.state.isCreateAccountSubmittableLeft == true &&
          this.state.isCreateAccountSubmittableMiddle == true &&
          this.state.isCreateAccountSubmittableRight == true &&
          this.state.createPassword != null &&
          this.state.confirmPassword != null &&
          this.state.createPassword == this.state.confirmPassword);
      //console.log("isLoginSubmittable: " + isLoginSubmittable);
      this.observer.publish("LoginSubmittable", isLoginSubmittable);
    });
  }

  addToServer = async (id) => {
    let numPlayersInServer = await this.getNumUsers(this.serverCollectionRef);
    //console.log("numPlayersInServer");
    //console.log(numPlayersInServer);
    let tempId = "Player " + (1 + numPlayersInServer);

    let tempDoc = doc(db, "server " + this.state.currentServerNumber, tempId);

    await setDoc(tempDoc, {
      score: 0,
      id: this.state.currentUserId,
      hand: 0,
    }); //problem here
  };

  getPageSetup() {
    if (this.state.page == pages.CREATE_ACCOUNT) {
      return <CreateAccount observer={this.observer} />;
    }

    if (this.state.page === pages.ARENA) {
      //no protection on this end, must stop users from getting into full servers on rightSide

      return (
        <Game
          currentServerNumber={this.state.currentServerNumber}
          currentUserId={this.state.currentUserId}
          observer={this.observer}
        />
      );
    }
    if (this.state.page === pages.ACCOUNT) {
      this.observer.publish("requestAccountInfo", this.state.currentUserId);
    }
    return (
      <React.Fragment>
        <LeftSide
          username={this.state.username}
          page={this.state.page}
          observer={this.observer}
          currentUserId={this.state.currentUserId}
        />
        <RightSide
          page={this.state.page}
          observer={this.observer}
          currentServerNumber={this.state.currentServerNumber}
        />
        <div className="title">RPS</div>
      </React.Fragment>
    );
  }

  render() {
    //this is called within setstate
    //console.log("FullPage.jsx Render Called: " + this.state.page);
    //console.log("");
    return this.getPageSetup();
  }
}

export default FullPage;
