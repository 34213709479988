import React, { Component } from "react";
import { pages } from "../pages.js";
import InputField from "./inputField.jsx";
import Login from "./login.jsx";
import Back from "./back.jsx";

class CreateAccount extends React.Component {
  constructor(props) {
    super(props);
    this.observer = props.observer;
    this.buttonClasses =
      "test fillParent defaultColor thirdPageWidth rightSideDashedBorder cursorText";
    this.textClasses = "login";
    this.state = {
      buttons: [
        {
          content: (
            <button
              key="CreateUserName"
              className={this.buttonClasses}
              onClick={() => {
                document.getElementById("CreateUserName").focus();
              }}
            >
              <p className={this.textClasses}>
                Create <br></br> Username
              </p>
              <InputField
                title="Must be at least 3 characters"
                observer={this.observer}
                type="text"
                minLength="3"
                action=""
                name="CreateUserName"
                id="CreateUserName"
              />
            </button>
          ),
        },
        {
          content: (
            <button
              key="CreatePassword"
              className={this.buttonClasses}
              onClick={() => {
                document.getElementById("CreatePassword").focus();
              }}
            >
              <p className={this.textClasses}>
                Create <br></br> Password
              </p>
              <InputField
                title="Must be at least 6 characters"
                observer={this.observer}
                type="password"
                minLength="6"
                action=""
                name="CreatePassword"
                id="CreatePassword"
              />
            </button>
          ),
        },
        {
          content: (
            <button
              key="ConfirmPassword"
              className={this.buttonClasses}
              onClick={() => {
                document.getElementById("ConfirmPassword").focus();
              }}
            >
              <p className={this.textClasses}>
                Confirm <br></br> Password
              </p>
              <InputField
                title="Must be at least 6 characters"
                observer={this.observer}
                type="password"
                minLength="6"
                action=""
                name="ConfirmPassword"
                id="ConfirmPassword"
              />
            </button>
          ),
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="create-top">
          <Back
            name="back.CreateAccount"
            bonusClasses="halfPage"
            observer={this.observer}
            previousPage={pages.TITLE_PAGE}
          />
          <Login
            name="login.CreateAccount"
            style={{ width: "50%" }}
            type = "Create Account"
            observer={this.observer}
            bonusClasses="halfPage"
          />
        </div>

        <div className="create-bottom">
          {this.state.buttons.map((button) => {
            return button.content;
          })}
        </div>
      </React.Fragment>
    );
  }
}

export default CreateAccount;
