import React, { Component } from "react";
import { pages } from "../pages.js";
import InputField from "./inputField.jsx";
import Back from "./back.jsx";

class LeftSide extends React.Component {
  constructor(props) {
    super(props);
    this.observer = this.props.observer;
    this.state = {
      accountInfo: "",
    };
  }

  componentDidMount() {
    //console.log("LeftSide: currentUserId");
    //console.log(this.props.currentUserId);
    this.observer.subscribe("responseAccountInfo", (accountInfo) => {
      console.log(accountInfo);
      this.setState({ accountInfo: accountInfo.accountInfo });
    });
  }

  componentWillUnmount() {
    this.observer.unsubscribe("responseAccountInfo");
  }

  getPercentage(hand) {
    if (
      this.state.accountInfo.rock == 0 &&
      this.state.accountInfo.paper == 0 &&
      this.state.accountInfo.scissors == 0
    ) {
      return 0.0;
    }

    if (hand == "rock") {
      return (
        (this.state.accountInfo.rock /
          (this.state.accountInfo.rock +
            this.state.accountInfo.paper +
            this.state.accountInfo.scissors)) *
        100
      ).toFixed(2);
    }
    if (hand == "paper") {
      return (
        (this.state.accountInfo.paper /
          (this.state.accountInfo.rock +
            this.state.accountInfo.paper +
            this.state.accountInfo.scissors)) *
        100
      ).toFixed(2);
    }
    if (hand == "scissors") {
      return (
        (this.state.accountInfo.scissors /
          (this.state.accountInfo.rock +
            this.state.accountInfo.paper +
            this.state.accountInfo.scissors)) *
        100
      ).toFixed(2);
    }
  }

  getWL() {
    return this.state.accountInfo.losses == 0
      ? "0.0"
      : this.state.accountInfo.wins / this.state.accountInfo.losses;
  }

  pageFinder() {
    switch (this.props.page) {
      case pages.TITLE_PAGE:
        return (
          <button
            onClick={() => {
              //console.log(this.observer.getAllEvents());
              this.observer.publish("changePage", pages.LOGIN);
            }}
            className="fillParent defaultColor"
          >
            Login
          </button>
        );

      case pages.LOGIN: //TODO: add autofocus attribute to a sides input field when a sides button is clicked
        return (
          <div className="fillParent">
            <Back
              name="back.Login"
              observer={this.observer}
              previousPage={pages.TITLE_PAGE}
            />
            <button
              style={{ position: "absolute", display: "block", zIndex: 0 }}
              className="cursorText fillWithOption defaultColor "
              onClick={() => {
                document.getElementById("LoginUserName").focus();
              }}
            >
              <p className="login">Username</p>
              <InputField
                title="Must be at least 3 characters."
                side="left"
                observer={this.observer}
                type="text"
                minLength="3"
                action=""
                name="LoginUserName"
                id="LoginUserName"
              />
            </button>
          </div>
        ); //TODO ACTION

      case pages.HOMEPAGE:
        return (
          <div className="fillParent ">
            <button
              className="defaultColor topOption bottomSideDashedBorder "
              onClick={() => {
                this.observer.publish("changePage", pages.ACCOUNT);
              }}
            >
              {this.props.username}
            </button>
            <button
              // onClick={() => {
              //   this.observer.publish("changePage", pages.LOCAL);
              // }}
              className="fillWithOption notInteractableColor"
            >
              Local
            </button>
          </div>
        );

      case pages.ACCOUNT:
        console.log(this.state.accountInfo);
        return (
          //defaultColor notInteractableColor
          <>
            <div className="notInteractableColor  account-username">
              {this.state.accountInfo.username}
            </div>
            <div className="notInteractableColor fillParent flex-container">
              <div>Win/Loss: {this.getWL()}</div>
              <div>Rock: {this.getPercentage("rock")}%</div>
              <div>Paper: {this.getPercentage("paper")}%</div>
              <div>Scissors: {this.getPercentage("scissors")}%</div>
            </div>
          </>
        );

      case pages.ONLINE:
        return (
          <button
            onClick={() => {
              console.log("Accountside Back Button Pressed");
              this.observer.publish("changePage", pages.HOMEPAGE);
            }}
            className="fillParent defaultColor homePage"
          >
            Back
          </button>
        );

      default:
        return <h1>Fail</h1>;
    }
  }

  render() {
    return <div className="leftHalf">{this.pageFinder()}</div>;
  }
}

export default LeftSide;
