import React, { Component } from "react";
import { pages } from "../pages.js";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmittable: false,
    };

    this.observer = props.observer;
  }

  componentDidMount() {
    this.observer.subscribe("LoginSubmittable", (data) => {
      //console.log("data recieved: " + data);
      this.setState({ isSubmittable: data });
    });
  }

  componentWillUnmount() {
    this.observer.unsubscribe("LoginSubmittable");
  }

  getButtonClasses() {
    let submissionButtonClasses =
      "topOption bottomSideDashedBorder leftSideDashedBorder transition";
    submissionButtonClasses += this.state.isSubmittable
      ? " submittable"
      : " notInteractableColor";
    return submissionButtonClasses;
  }

  render() {
    return (
      <button
        className={this.getButtonClasses() + " " + this.props.bonusClasses}
        onClick={() => {
          if (this.state.isSubmittable) {
            this.observer.publish("login", this.props.type);
            this.observer.publish("changePage", pages.HOMEPAGE);
          }
        }} // this probably doesnt do this
      >
        Login
      </button>
    );
  }
}

export default Login;
