import React, { Component } from "react";
import { pages } from "../pages.js";

class Back extends React.Component {
  constructor(props) {
    super(props);
    this.observer = props.observer;
  }
  render() {
    return (
      <button
        className={
          "defaultColor topOption bottomSideDashedBorder " +
          this.props.bonusClasses
        }
        onClick={() => {
          console.log(this.props.name + " clicked");
          console.log(
            this.props.name + "previousPage: " + this.props.previousPage
          );
          this.observer.publish("setLoginSubmittableFalse");
          this.observer.publish("setCreateAccountSubmittableFalse");
          this.observer.publish("changePage", this.props.previousPage);
        }}
      >
        Back
      </button>
    );
  }
}

export default Back;
