import React, { Component } from "react";

class InputField extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.observer = props.observer;
  }

  componentDidMount() {
    // this.observer.subscribe("inputFieldChanged", (data) => {
    //   console.log(data);
    // });
  }

  render() {
    //console.log(this.props.side);
    return (
      <form action={this.props.action}>
        <input
          ref={this.inputRef}
          onChange={() => {
            //this.props.onChange(this.props.side);
            //if (this.inputRef.current.value.length >= this.props.minLength) {
            this.observer.publish("inputFieldChanged", {
              name: this.props.name,
              value: this.inputRef.current.value,
            });
            // }
          }}
          title={this.props.title}
          style={this.props.styles}
          autoComplete="true"
          type={this.props.type}
          size="20"
          minLength={this.props.minLength}
          maxLength="12"
          id={this.props.id}
          name={this.props.name}
          required
        ></input>
      </form>
    );
  }
}

export default InputField;
